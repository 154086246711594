import React from 'react';
import { defaultDescription, defaultHeadline, defaultLogos, LogosBlockTestId } from './constants';
import './style.scss';

export interface ILogoItem {
  title: string;
  description: string;
  image: string;
  bgCls?: string;
  png: string;
  zip: string;
}

export interface Props {
  headline?: string;
  description?: React.ReactNode;
  logos?: ILogoItem[];
}

export const LogosBlock: React.FC<Props> = ({
  headline = defaultHeadline,
  description = defaultDescription,
  logos = defaultLogos,
}) => {
  return (
    <section data-testid={LogosBlockTestId} className="container-fluid bg-asphalt py-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <h3 className="text-primary fz-18 text-uppercase text-center mb-4">{headline}</h3>
          </div>
          <div className="col-md-9 col-12  mx-auto text-center">{description}</div>
        </div>
        <div className="row">
          {logos.map((logo, idx) => {
            const downloadName = logo.title.toLowerCase().replace(/\s/g, '-');
            return (
              <div className="col-md-4 col-12 mb-5" key={idx}>
                <div className={` ${logo.bgCls} logo-img d-flex justify-content-center mb-3 p-3`}>
                  <img src={logo.image} alt={logo.title} className="img-fluid" />
                </div>
                <p className="fz-12 mb-1 font-weight-bold">{logo.title}</p>
                <p className="fz-12 ">{logo.description}</p>
                <div className="d-flex">
                  <a
                    rel="noopener noreferrer"
                    download={downloadName}
                    target="_blank"
                    href={logo.image}
                    className="btn btn-primary btn-sm fz-10 mr-2 text-white"
                  >
                    SVG
                  </a>
                  <a
                    rel="noopener noreferrer"
                    download={downloadName}
                    target="_blank"
                    className="btn btn-outline-primary btn-sm fz-10 mr-2"
                    href={logo.png}
                  >
                    PNG
                  </a>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    download={`${downloadName}.zip`}
                    href={`/${logo.zip}`}
                    className="btn btn-outline-light btn-sm fz-10"
                  >
                    ZIP
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default LogosBlock;
