import React from 'react';
import PageHeader from '../../components/PageHeader';
import { genericPageBlockMapper } from './genericPageBlockMapper';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const pressPageBlockMapper = (blocks: GatsbyTypes.ContentfulPage['pageBlocks']) => {
  const components = genericPageBlockMapper(blocks);

  blocks?.forEach((block, index: number) => {
    switch (block?.internal.type) {
      case 'ContentfulComponentPageHeader':
        block = block as GatsbyTypes.ContentfulComponentPageHeader;
        components[index] = (
          <PageHeader
            key={index}
            textTop={block?.textTop || ''}
            textMiddle={block?.textMiddle}
            textBottom={block?.textBottom}
            backgroundImage={block?.backgroundImage?.gatsbyImageData}
            subHeader={
              <div className="container">
                <h2 className="fz-24 mb-0">
                  For Press Inquiries, please contact us at <a href="mailto:press@stackhawk.com">press@stackhawk.com</a>
                </h2>
              </div>
            }
          />
        );

        break;
      default:
        break;
    }
  });

  return components;
};
