import React, { useState } from 'react';
import { defaultHeadline, loadMoreButtonText, RecentNewsTestId } from './constants';
import './style.scss';
import { INews } from '../FavoriteNews';
import ExternalLink from 'components/ExternalLink';

export interface Props {
  headline?: string;
  news: INews[];
  showMoreButton?: boolean;
  showMoreItems?: number;
}

export const RecentNews: React.FC<Props> = ({
  headline = defaultHeadline,
  news = [],
  showMoreButton = true,
  showMoreItems = 10,
}) => {
  const [showMore, setShowMore] = useState(showMoreButton);
  return (
    <section data-testid={RecentNewsTestId} className="container-fluid">
      <div className="container py-5 recent-news">
        <div className="row mb-4">
          <div className="col-12">
            <h3 className="text-primary fz-18 text-uppercase">{headline}</h3>
          </div>
        </div>

        {news.map((item, idx) => {
          const isReadMore = idx > showMoreItems - 1;
          return (
            <>
              <div className={`row mb-4 ${showMore && isReadMore ? 'd-none' : ''}`} key={idx}>
                <div className="col-12">
                  <div className=" bg-asphalt px-4 pt-4 pb-5  rounded recent-news-item">
                    <p className="fz-12 text-white mb-3">
                      {item.publishedBy} | {item.publicationDate}
                    </p>

                    <ExternalLink href={item.url} className="text-white mb-0 fz-18">
                      {item.newsHeadline}
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        {showMore && (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <button className="btn btn-secondary" onClick={() => setShowMore(false)}>
                {loadMoreButtonText}
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default RecentNews;
