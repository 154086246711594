import { INews } from './index';

export const FavoriteNewsTestId = 'FavoriteNews';
export const defaultHeadline = 'our favorite news';
export const defaultFavoriteNews: INews[] = [
  {
    headline:
      'StackHawk, the Denver-based bug-detecting service, hires developer of open-source project Zed Attack Proxy',
    publishedBy: 'TechCrunch',
    publicationDate: 'July 15, 2020',
    url: 'https://techcrunch.com/2020/07/15/stackhawk-the-denver-based-bug-detecting-service-hires-developer-of-open-source-project-zed-attack-proxy/',
    isFavorite: true,
  },
  {
    headline:
      'Denver startup StackHawk just raised a $10 million Series A to help developers automatically find security flaws in their apps',
    publishedBy: 'Business Insider [Paywall]',
    publicationDate: 'October 27, 2020',
    url: 'https://www.businessinsider.com/stackhawk-developer-security-ceo-klippert-sapphire-funding-2020-10',
    isFavorite: true,
  },
];
