import * as React from 'react';
import { graphql } from 'gatsby';
import Default from '../../layouts/default';
import { pressPageBlockMapper } from '../../core/pageHelpers/press';
import FavoriteNews from '../../components/FavoriteNews';
import RecentNews from '../../components/RecentNews';
import LogosBlock from '../../components/LogosBlock';
import SEO from 'components/seo';

const Press: React.FC<{ data: any }> = ({ data }) => {
  const pageData: GatsbyTypes.ContentfulPage = data.press;
  const page = pageData.edges[0].node;
  const seo = page.seo;

  const blocks = Object.values(pressPageBlockMapper(page.pageBlocks));
  const newsData: GatsbyTypes.ContentfulBlockNewsConnection = data.news;
  const news = newsData.edges.map((item) => item.node);

  const favoriteNews = news.filter((item) => item.isFavorite);
  const recentNews = news.filter((item) => !item.isFavorite).reverse();

  return (
    <>
      <Default>
        <SEO data={seo} />
        {blocks.map((block) => block)}
        <FavoriteNews news={favoriteNews} />
        <RecentNews news={recentNews} />
        <LogosBlock />
      </Default>
    </>
  );
};

export const query = graphql`
  {
    press: allContentfulPage(filter: { page: { eq: "Press" } }) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...pressFields
        }
      }
    }
    news: allContentfulBlockNews(sort: { fields: publicationDate }) {
      edges {
        node {
          ...ContentfulBlockNews
        }
      }
    }
  }
`;

export default Press;
