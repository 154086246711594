import { ILogoItem } from './index';
import LogoWhite from '../../images/logos/stackhawk-full-light.svg';
import LogoDark from '../../images/logos/stackhawk-full-dark.svg';
import LogoWhiteStacked from '../../images/logos/stackhawk-stacked-light.svg';
import LogoDarkStacked from '../../images/logos/stackhawk-stacked-dark.svg';

import LogoWhitePng from '../../images/logos/stackhawk-full-light.png';
import LogoDarkPng from '../../images/logos/stackhawk-full-dark.png';
import LogoWhiteStackedPng from '../../images/logos/stackhawk-stacked-light.png';
import LogoDarkStackedPng from '../../images/logos/stackhawk-stacked-dark.png';
export const LogosBlockTestId = 'LogosBlock';
export const defaultHeadline = 'Stackhawk Logos';
export const defaultDescription =
  'Feel free to download and use the StackHawk logo assets below. Please do not modify or change the logos below. Please be sure to follow the usage rules noted under each asset.';

export const defaultLogos: ILogoItem[] = [
  {
    description:
      'Use this logo in most circumstances on light or white backgrounds. Please be sure use keep 32px of padding around the logo.',
    image: LogoWhite,
    title: 'Full Logo Light',
    bgCls: 'logo-white',
    png: LogoWhitePng,
    zip: 'stackhawk-full-light.zip',
  },
  {
    description:
      'Use this logo in most circumstances on dark backgrounds. Please be sure use keep 32px of padding around the logo.',
    image: LogoDark,
    title: 'Full Logo Dark',
    bgCls: 'bg-dark',
    png: LogoDarkPng,
    zip: 'stackhawk-full-dark.zip',
  },
  {
    description:
      'Use this logo in most circumstances on dark backgrounds. Please be sure use keep 32px of padding around the logo.',
    image: LogoWhiteStacked,
    title: 'Stacked Logo Light',
    bgCls: 'logo-white',
    png: LogoWhiteStackedPng,
    zip: 'stackhawk-stacked-light.zip',
  },
  {
    description:
      'Use this logo in cases where the logo is surrounded by blocks of text. Please be sure use keep 48px of padding around the logo.',
    image: LogoDarkStacked,
    title: 'Stacked Logo Dark',
    bgCls: 'bg-dark',
    png: LogoDarkStackedPng,
    zip: 'stackhawk-stacked-dark.zip',
  },
];
