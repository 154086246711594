import React, { useState } from 'react';
import { defaultHeadline, FavoriteNewsTestId } from './constants';
import './style.scss';
import ExternalLink from '../ExternalLink';

export interface INews {
  newsHeadline: string;
  publishedBy: string;
  publicationDate: string;
  url: string;
  isFavorite?: boolean;
}

export interface Props {
  headline?: React.ReactNode;
  news: INews[];
}

export const FavoriteNews: React.FC<Props> = ({ headline = defaultHeadline, news }) => {
  const [selectedNew, setSelectedNew] = useState<INews>(news[0]);

  return (
    <section data-testid={FavoriteNewsTestId} className="container-fluid">
      <div className="container py-5 favorite-news">
        <div className="row mb-4">
          <div className="col-12">
            <h3 className="text-primary fz-18 text-uppercase">{headline}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12 mb-4">
            {news.map((item, idx) => (
              <p
                role="button"
                key={idx}
                className={`pl-3 mb-4 favorite-news-selector btn text-left p-0 rounded-0  ${
                  selectedNew.url === item.url ? 'selected text-primary' : 'text-white-50 '
                }`}
                onClick={() => setSelectedNew(news[idx])}
              >
                {item.newsHeadline}
              </p>
            ))}
          </div>
          <div className="col-md-8 col-12">
            <div className=" bg-asphalt pt-4 px-4 mb-6 mb-md-0 pb-lg-7 favorite-news-featured rounded">
              <div className="d-flex align-items-center justify-content-center mb-4">
                <p className="fz-12 text-white mb-0">
                  {selectedNew.publishedBy} | {selectedNew.publicationDate}
                </p>
                <ExternalLink href={selectedNew.url} className="btn btn-outline-light btn-sm ml-auto">
                  Read More
                </ExternalLink>
              </div>
              <h4 className="text-primary mb-0 fz-18">
                <ExternalLink href={selectedNew.url} className="text-white">
                  {selectedNew.newsHeadline}
                </ExternalLink>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FavoriteNews;
